import { Button, Grid } from '@mui/material'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { SignInServerPageParams } from 'next-auth/core/pages/signin'
import { getProviders, signIn } from 'next-auth/react'
import React from 'react'

const Page: NextPage<SignInServerPageParams> = (props) => {
    const { providers } = props

    const router = useRouter()

    // callbackUrl クエリ文字列の値を明示的に渡さないと、ログイン成功後に callbackUrl にリダイレクトしない。
    // see: https://github.com/nextauthjs/next-auth/issues/591#issuecomment-715992773
    const callbackUrl = Array.isArray(router.query.callbackUrl)
        ? router.query.callbackUrl[0]
        : router.query.callbackUrl

    return (
        <Grid container alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
            {Object.values(providers).map((provider) => (
                <Button
                    key={provider.name}
                    onClick={() => signIn(provider.id, { callbackUrl })}
                    variant="contained"
                    size="large"
                >
                    {provider.name} でサインイン
                </Button>
            ))}
        </Grid>
    )
}

export default Page

export async function getServerSideProps(_context: any) {
    const providers = await getProviders()
    return {
        props: { providers },
    }
}
